import React from 'react'

import { Box, Typography } from '@material-ui/core'

import { ReactComponent as LogoSymbol } from 'src/assets/images/logo-symbol.svg'

import { POSVerifyRouteAccess } from '..'
import { SubmitWhatsAppAccept, WhatsAppAccept } from '../whatsapp-accept'
import { Buyer } from 'src/providers/Main'

type SuccessTextProps = {
    matches?: boolean
    buyer?: Partial<Buyer>
    onSubmit: SubmitWhatsAppAccept
    title: string
    subtitle: string
}

export const SuccessText = ({
    matches,
    buyer,
    onSubmit,
    title,
    subtitle,
}: SuccessTextProps) => {
    return (
        <>
            <POSVerifyRouteAccess condition={!buyer?.phone} />
            <Box display="inline-flex" alignItems="center" mb={3}>
                <LogoSymbol />
                <Box mr={1} />
                <Typography
                    color="primary"
                    variant={matches ? 'h4' : 'h3'}
                    style={{ fontWeight: 700 }}
                >
                    Quase lá!
                </Typography>
            </Box>
            <Box maxWidth={594}>
                <Typography
                    variant={matches ? 'subtitle1' : 'h5'}
                    paragraph
                    color="textSecondary"
                    style={{ fontWeight: 500 }}
                >
                    {title}
                </Typography>
            </Box>
            <Box maxWidth={503}>
                <Typography
                    variant={matches ? 'body2' : 'body1'}
                    color="textSecondary"
                >
                    {subtitle}
                </Typography>
            </Box>

            <WhatsAppAccept
                matches={matches}
                phone={buyer?.phone}
                countryCode={buyer?.countryCode}
                onSubmit={onSubmit}
            />
        </>
    )
}
