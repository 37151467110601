import { useMutation, UseQueryOptions } from 'react-query'

import { axiosInstance } from 'src/config/data-access/axios'

interface ILead {
    name: string
    email: string
    phone: string
    courseId: number
    utmSource?: string
    utmMedium?: string
    utmCampaign?: string
}

export const useCreateLead = (
    options?: UseQueryOptions<any, any, any, any>
) => {
    return useMutation((lead: ILead) => {
        return axiosInstance.post('/v1/lead', lead).then(({ data }) => data)
    }, options)
}
