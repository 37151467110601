import React from 'react'
import ReactDOM from 'react-dom'

import { initializeTrackers } from 'src/config/trackers'

import { POSScrollToTop } from 'src/components'

import { Providers } from 'src/providers'

import reportWebVitals from './reportWebVitals'

import App from './App'

import { Buffer } from 'buffer'

window.Buffer = Buffer

initializeTrackers()

ReactDOM.render(
    <React.StrictMode>
        <Providers>
            <POSScrollToTop />
            <App />
        </Providers>
    </React.StrictMode>,
    document.getElementById('root')
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
