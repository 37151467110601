/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    styled,
    Theme,
    Typography,
    useTheme,
} from '@material-ui/core'
import { IProductData } from 'src/providers/Main'
import { ReactComponent as CreditCardIcon } from 'src/assets/images/credit-card.svg'
import { ReactComponent as BankSlipIcon } from 'src/assets/images/bank-slip.svg'
import { PaymentMethodType } from 'src/types'
import { formatCurrency, formatCurrencyWithoutCents } from 'src/utils'
import { ArrowDropDown } from '@material-ui/icons/'

type TPaymentSelection = {
    products: IProductData[]
    productSelected?: IProductData
    setProductSelectValue: any
    paymentMethodSelectValue: PaymentMethodType
    handleChangePlan: any
    hasBankSlip: boolean
    isPromoEnabled: boolean
    isRecurrenceEnabled: boolean
}

type TSelectLabel = {
    isSelected: boolean
    theme: Theme
}

const SelectDiscountLabel = styled(Typography)(
    ({ theme, isSelected }: TSelectLabel) => ({
        color: isSelected
            ? theme.palette.info.main
            : theme.palette.primary.dark,
        fontWeight: isSelected ? 'bold' : 'normal',
        fontSize: '12px',
    })
)

const SelectPriceLabel = styled(Typography)(
    ({ theme, isSelected }: TSelectLabel) => ({
        fontWeight: isSelected ? 'bold' : 'normal',
        color: theme.palette.primary.dark,
        fontSize: '16px',
        marginRight: '20px',
    })
)

const SelectArrow = styled(ArrowDropDown)(() => ({
    width: '1.4em',
    height: '1.4em',
    top: 'calc(50% - 16px)',
}))

const PaymentSelection = ({
    products,
    productSelected,
    paymentMethodSelectValue,
    handleChangePlan,
    hasBankSlip,
    isPromoEnabled,
    isRecurrenceEnabled,
}: TPaymentSelection) => {
    const theme = useTheme()
    const [betterDiscount, setBetterDiscount] = useState(0)

    const productsByPaymentMethod = products.filter(
        (product) => product.paymentMethod === paymentMethodSelectValue
    )

    const isSelectedProduct = (uniqueId: string) =>
        productSelected?.uniqueId === uniqueId

    useEffect(() => {
        const findBetterDiscount = () => {
            const discountsPercentage = products.flatMap((product) => {
                if (product.paymentMethod === PaymentMethodType.CREDIT_CARD)
                    return product.discountsPercentageTotal
                return 0
            })

            return Math.max.apply(null, discountsPercentage)
        }

        setBetterDiscount(findBetterDiscount())
    }, [betterDiscount])

    return (
        <>
            {hasBankSlip ? (
                <Box display={'flex'} alignItems={'center'}>
                    <BankSlipIcon />
                    <Box display="flex" flexDirection="column" marginLeft={1.5}>
                        <Typography
                            paragraph
                            style={{ fontWeight: 700, margin: 0 }}
                        >
                            Boleto
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Box display={'flex'} alignItems={'center'}>
                    <CreditCardIcon />
                    <Box display="flex" flexDirection="column" marginLeft={1.5}>
                        <Typography
                            paragraph
                            style={{ fontWeight: 700, margin: 0 }}
                        >
                            Cartão de Crédito
                        </Typography>
                        {!!betterDiscount && betterDiscount > 0 && (
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{
                                    fontSize: '12px',
                                    lineHeight: '1',
                                    color: theme.palette.info.main,
                                    letterSpacing: '0.04em',
                                    fontWeight: 700,
                                }}
                            >
                                {`Até ${betterDiscount}% de desc.`}
                            </Typography>
                        )}
                    </Box>
                </Box>
            )}
            <Box mt={3} />
            <Typography> Escolha as condições de pagamento: </Typography>

            <Box mt={2} />
            {!hasBankSlip &&
            isPromoEnabled &&
            productSelected?.paymentMethod === PaymentMethodType.CREDIT_CARD ? (
                <Box
                    mt={2}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#010E2C"
                    height="42px"
                    borderRadius="8px 8px 0px 0px"
                >
                    {productSelected?.installments === 1 ? (
                        <Typography
                            style={{
                                color: '#fff',
                                fontSize: '16px',
                                fontWeight: 700,
                            }}
                        >
                            Desconto especial
                        </Typography>
                    ) : (
                        <>
                            <Typography
                                style={{
                                    color: '#fff',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                }}
                            >
                                {`1ª parcela de ${formatCurrency.format(
                                    productSelected?.priceFirstInstallment || 0
                                )}*`}
                            </Typography>
                            &nbsp;
                            <Typography
                                style={{
                                    color: '#fff',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                }}
                            >
                                +
                            </Typography>
                        </>
                    )}
                </Box>
            ) : (
                <Box mt={2} />
            )}

            <FormControl fullWidth>
                <Select
                    id="payment-select"
                    value={productSelected?.uniqueId}
                    onChange={handleChangePlan}
                    style={{
                        backgroundColor: theme.palette.background.default,
                    }}
                    disableUnderline
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                    IconComponent={SelectArrow}
                    defaultValue={''}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        <Typography style={{ padding: '11px 15px' }}>
                            -- Selecione uma condição --
                        </Typography>
                    </MenuItem>
                    {productsByPaymentMethod?.map((product) => {
                        const isSelected = isSelectedProduct(product.uniqueId)

                        const hasDiscount = product.discountsPercentageTotal > 0

                        const installments = isRecurrenceEnabled
                            ? product.totalInstallments
                            : !hasBankSlip &&
                              isPromoEnabled &&
                              product.installments > 1
                            ? product.installments - 1
                            : product.installments

                        return (
                            <MenuItem
                                value={product.uniqueId}
                                key={product.uniqueId}
                            >
                                <Box display="flex" padding="11px 15px">
                                    <SelectPriceLabel
                                        isSelected={isSelected}
                                    >{`${installments}x de ${formatCurrencyWithoutCents.format(
                                        product.installmentPrice
                                    )}`}</SelectPriceLabel>
                                    {hasDiscount && (
                                        <SelectDiscountLabel
                                            isSelected={isSelected}
                                        >
                                            {`(${Math.floor(
                                                product.discountsPercentageTotal
                                            )}% desc.)`}
                                        </SelectDiscountLabel>
                                    )}
                                </Box>
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </>
    )
}

export default PaymentSelection
