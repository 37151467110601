import {
    Box,
    FormControlLabel,
    FormHelperText,
    Link,
    Radio,
    RadioGroup,
    RadioProps,
    Theme,
    Typography,
    makeStyles,
    useTheme,
} from '@material-ui/core'
import { PaymentMethodType } from '../../../types'
import { formatCurrency } from '../../../utils'
import { IProductData } from '../../../providers/Main'
import { useState } from 'react'
import { useModal } from '../../../components/modal'
import RecurrenceInfoModal from '../components/RecurrenceInfoModal/RecurrenceInfoModal'

type TRecurrenceSelection = {
    products: IProductData[]
    productSelected?: IProductData
    setProductSelectValue: any
}

type TRadioBox = {
    installments: number
    amount: number
    hasError: boolean
    discountPercentage?: number
    description?: string
    product: IProductData
} & RadioProps

const filterOnlyCreditCard = (products: IProductData[]): IProductData[] => {
    return products.filter(
        (product) => product.paymentMethod === PaymentMethodType.CREDIT_CARD
    )
}

const useStyles = makeStyles<Theme, { checked: boolean; error: boolean }>(
    (theme) => ({
        box: {
            color: (props) =>
                props.checked
                    ? theme.palette.common.white
                    : theme.palette.secondary.main,
            backgroundColor: (props) =>
                props.checked
                    ? theme.palette.secondary.main
                    : theme.palette.common.white,
            boxShadow: (props) =>
                `0px 1px 4px 0px ${
                    props.error
                        ? theme.palette.error.main
                        : 'rgba(17, 18, 18, 0.25)'
                }`,
        },
        typography: {
            color: (props) =>
                props.checked
                    ? theme.palette.common.white
                    : theme.palette.secondary.main,
            opacity: (props) => (props.checked ? '85%' : '100%'),

            backgroundColor: (props) =>
                props.checked
                    ? theme.palette.secondary.main
                    : theme.palette.common.white,
        },
        description: {
            color: (props) =>
                props.checked
                    ? theme.palette.common.white
                    : 'rgba(17, 18, 18, 0.75)',
            opacity: (props) => (props.checked ? '85%' : '100%'),
        },
        caption: {
            color: theme.palette.success.main,
            fontWeight: 700,
            fontSize: '0.75rem',
        },
        radio: {
            color: '#000',
            opacity: '60%',
            '&.Mui-checked': {
                color: theme.palette.common.white,
                opacity: '85%',
            },
            padding: '0 0.5rem',
        },
        link: {
            color: (props) =>
                props.checked
                    ? theme.palette.common.white
                    : theme.palette.primary.main,
        },
    })
)

const RadioBox = ({
    installments,
    amount,
    discountPercentage,
    description,
    hasError,
    product,
    ...rest
}: TRadioBox) => {
    const classes = useStyles({ checked: !!rest.checked, error: hasError })
    const { open, toggleModal } = useModal()

    return (
        <Box borderRadius={8} padding={2} className={classes.box}>
            <FormControlLabel
                style={{ alignItems: 'flex-start' }}
                control={<Radio {...rest} className={classes.radio} />}
                label={
                    <>
                        <Typography
                            style={{
                                fontWeight: 700,
                                fontSize: '1rem',
                            }}
                            className={classes.typography}
                        >
                            {installments}x de {formatCurrency.format(amount)}
                        </Typography>
                        {discountPercentage && (
                            <Typography
                                variant="caption"
                                className={classes.caption}
                            >
                                ({discountPercentage}% de desconto)
                            </Typography>
                        )}
                        {description && (
                            <Typography
                                variant="body2"
                                style={{
                                    fontWeight: 500,
                                    fontSize: '0.75rem',
                                }}
                                className={classes.description}
                            >
                                {description}{' '}
                                <Typography
                                    onClick={toggleModal}
                                    component={Link}
                                    underline={'always'}
                                    color="primary"
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                        fontWeight: 500,
                                    }}
                                    className={classes.link}
                                >
                                    Saiba mais
                                </Typography>
                            </Typography>
                        )}
                    </>
                }
            />
            <RecurrenceInfoModal
                open={open}
                onClose={toggleModal}
                totalInstallments={product.totalInstallments!}
                numberOfCycles={product.numberOfCyles!}
                cycleInMonths={product.cycleInMonths!}
                installmentPrice={product.installmentPrice!}
                cycleTotalPrice={product.cycleTotalPrice!}
            />
        </Box>
    )
}

const RecurrenceSelection = ({
    products,
    productSelected,
    setProductSelectValue,
}: TRecurrenceSelection) => {
    const [error, setError] = useState(false)
    const theme = useTheme()

    const makeProductProps = (product: IProductData): TRadioBox => ({
        hasError: error,
        installments: product.totalInstallments,
        amount: product.installmentPrice,
        value: product.uniqueId,
        key: product.uniqueId,
        checked: product.uniqueId === productSelected?.uniqueId,
        product,
        ...(product.discountsPercentageTotal && {
            discountPercentage: product.discountsPercentageTotal,
        }),
        ...(product.cycleInMonths > 1 && {
            description: `Comprometa o limite do seu cartão em ${formatCurrency.format(
                product.cycleTotalPrice
            )} a cada ${product.cycleInMonths} meses.`,
        }),
    })

    return (
        <>
            <Typography variant="body1" paragraph>
                Como você prefere pagar?
            </Typography>
            <Box mt={3} />
            <RadioGroup
                name="recurrence-radio-group"
                onBlur={() => !productSelected && setError(true)}
                style={{ gap: '0.5rem' }}
                onChange={(ev) => {
                    setError(false)
                    setProductSelectValue(ev.target.value)
                }}
            >
                {filterOnlyCreditCard(products).map((product) => (
                    <RadioBox {...makeProductProps(product)} />
                ))}
            </RadioGroup>
            {error && (
                <FormHelperText style={{ color: theme.palette.error.main }}>
                    Selecione um plano de pagamento
                </FormHelperText>
            )}
        </>
    )
}

export default RecurrenceSelection
