export interface ICourseClasses {
    id: number
    description: string
    name: string
    enrollmentEndDate: string
    startDate: string
    endDate: string
    createdDate: string
    classSlug: string
    classStatusId: number
    courseId: number
    productSlug?: string
}

export interface ICourse {
    courseClasses: ICourseClasses[]
    eadWorkload: number
    id: number
    name: string
    practicalWorkload: number
    slug: string
    plans: IPlan[]
    coupon?: ICoupon | null
    status: string
    createdBy: string
    durationInMonths: number
    nucleus: string
    contract: IContract
}

export interface IProduct {
    createdDate: string
    description: string
    id: number
    installments: number
    isPrimary: boolean
    paymentMethod: PaymentMethodType
    price: number
}

export interface IContract {
    id: number
    link: string
}

type TPromoInfo = {
    numberOfCycles: number
    installmentValue: number
}

export type TPaymentInfo = {
    cycleTotalPrice: number
    totalValueToPay: number
    type: PaymentMethodType
    installmentPrice: number
    discountTotalValue: number
    discountPercentage: 10
    promo?: TPromoInfo
    availableBillingDays?: number[]
}

export interface IPlan {
    price: number
    slug: string
    installments: number
    numberOfCycles: number
    cycleInMonths: number
    isPrimary: boolean
    paymentMethodInfo: TPaymentInfo[]
}

export enum PaymentMethodType {
    CREDIT_CARD = 'credit_card',
    BANK_SLIP = 'bank_slip',
}

export interface IDiscount {
    description: string
    id: number
    percentage: number
    cycles?: number | null
    paymentMethodsId?: number
}

export interface ICoupon {
    id: number
    description: string
    percentage: number
    promoCode: string
    createdDate: string
    updatedDate: string
}

export type Dictionary = {
    [key: string]: string
}
